<template>
  <section class="confirmation-modal flex-center" @mousedown="_clickModal">
    <p class="text-center">{{ confirmationMsg }}</p>

    <div class="flex mtop14px">
      <button data-test="confirmation-yes" @click="action('da')">DA</button>
      <button @click="action('nu')">NU</button>
    </div>
  </section>
</template>

<script>
  import { EventBus } from '../main';
  import { mapState } from 'vuex';
  import { historyModal } from '../mixins/history-modal';
  export default {
    name: 'confirmation-modal',
    mixins: [historyModal],
    computed: {
      ...mapState({
        dinte: 'numeDinte',
        target: 'dinteElm',
        defaults: 'defaults',
        confirmationMsg: 'confirmationMsg',
        confirmationSubject: 'confirmationSubject'
      })
    },
    methods: {
      action (val) {
        if (val === 'da') {
          switch (this.confirmationSubject) {
            case 'dinte':
              const clbk = () => {
                this.target.parentElement.style.opacity = this.defaults.opacity;
                this.close();
              };
              this.$store.dispatch('sterge_dinte', { dinte: this.dinte, clbk });
              break;
            case 'pacient':
              EventBus.$emit('confirmation-yes');
              break;
          }
        } else {
          this.close();
        }
      },
      close () {
        this.$store.commit('SET_MODAL', {
          val: false,
          name: undefined
        });
      }
    }
  };
</script>

<style lang="less">
  @import "../assets/styles/var";
  .confirmation-modal {
    width: 400px;
    padding: 32px 24px;
    background-color: white;
    border-radius: 8px;
    flex-direction: column;

    p {
      font-size: 1.8rem;
    }

    div {
      button {
        font-size: 1.8rem;
        border-radius: 8px;
        font-weight: bold;
        padding: 4px 20px;
        cursor: pointer;
        color: white;
        border: none;
        &:first-child {
          background-color: @main-green;
        }
        &:last-child {
          background-color: @red;
          margin-left: 16px;
        }
      }
    }
  }
</style>
